@include media-breakpoint-down(md) {

    .menu-icon-img{
        max-width: 30%;
        max-height: 36px;
    }

    .mobile-nav-open {
        overflow-y: hidden;

        .level-1,
        .level-2,
        .level-3 {
            overflow-y: auto;
        }

        .page {
            overflow: visible;
        }
    }

    .page {
        transition: transform 0.5s $default-cubic-bezier;

        .mobile-nav-open > & {
            position: fixed;
            transform: translate3d(280px, 0, 0);
        }
    }

    .site-header {
        .main-nav {
            background-color: $site-cart-mobile-background;
            height: 100vh;
            left: 0;
            overflow: auto;
            position: fixed;
            top: 0;
            transform: translate3d(-280px, 0, 0);
            width: 280px;

            .container {
                padding: 0;
            }
            .nav-link {
                font-family: var(--custom-mobile-level-1-2-font-family, var(--font-family-sans-serif));
            }
        }

        .level-1 {
            z-index: 1;
        }

        .level-2 {
            z-index: 2;
        }

        .level-3 {
            z-index: 3;
            width: calc(100% + 2.5rem);
            background-color: $site-menu-level3-bg;

            .mega-menu__block-item .nav-link {
                font-family: var(--custom-mobile-level-3-font-family, var(--font-family-sans-serif)) !important;
            }
        }

        ul, div {
            .level-2 {
                background-color: $site-cart-mobile-background;
                height: 100vh;
                left: 0;
                position: absolute;
                top: 0;
                transform: translate3d(-280px, 0, 0);
                transition: transform 0.3s;
                width: 100%;

                .mega-menu__block {
                    flex-direction: column;
                    .lvl-2-link {
                        font-family: var(--custom-menu-category-name, var(--font-family-sans-serif, inherit));
                    }
                    .mega-menu__block-header.title[aria-expanded="true"] i::before{
                            content: "\f106";
                    }
                }
            }

            &.active {
                transform: translateX(0);
            }
        }

        .nav-item {
            font-weight: $font-weight-bold;
            padding: 0.25rem 1.25rem;
            text-transform: uppercase;
        }

        .nav-item:first-child {
            padding: 0.5rem 1.25rem 0.25rem 1.25rem;
        }

        .mobile-nav-forward {
            cursor: pointer;

            i {
                padding: 5px;
            }
        }

        .menu-devider {
            border-top: 1px solid $gray-400;
        }
    }
}
