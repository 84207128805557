$primary: #242424 !default;
$secondary: #000000 !default;
$tertiary: #ffffff !default;

$action-primary: #d51b4a !default;

$font-family-base: "Roboto", sans-serif !default;
$font-family-accent: "SpaceGrotesk", sans-serif !default;

$font-size-base: 1rem !default;
$font-weight-light: 200 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600 !default;


$h1-font-family: $font-family-accent !default;;
$h1-font-size: $font-size-base * 4 !default;
$h1-font-weight: $font-weight-bold;
$h2-font-family: $font-family-accent !default;;
$h2-font-size: $font-size-base * 3 !default;
$h2-font-weight: $font-weight-bold;
$h3-font-family: $font-family-accent !default;;
$h3-font-size: $font-size-base * 2.125 !default;
$h3-font-weight: $font-weight-bold;
$h4-font-family: $font-family-base !default;;
$h4-font-size: $font-size-base * 1.5!default;
$h4-font-weight: $font-weight-normal;
$h5-font-family: $font-family-base !default;;
$h5-font-size: $font-size-base * 1 !default;
$h5-font-weight: $font-weight-normal;
$h6-font-family: $font-family-base !default;;
$h6-font-size: $font-size-base * 0.875 !default;
$h6-font-weight: $font-weight-light;

$category-banner-overlay-color: $primary !default;
$category-banner-header-overlay-color: $tertiary !default;

$site-menu-level3-font: $font-family-base !default;

@import "~core/variables";