@import "tooltip";

/* glass-references-section */
/* reference-screens-section */
.glass-references-nav,
.reference-screens-nav {
    &.nav-tabs {
        padding: 0;

        .nav-item {
            padding: 0;
        }

        .nav-link {
            background-color: $secondary;
            color: $white;
            border: 0;
            box-shadow: none;
        
            &.active,
            &:focus,
            &:hover,
            &:focus:hover {
                background-color: $accent;
                color: $white;
                border: 0;
                box-shadow: none;
            }

            &.active::after {
                content: ' ';
                position: absolute;
                left: 45%;
                bottom: -20%;
                border-top: 20px solid $accent;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
            }
        }
    }
}

.reference-screens-nav {
    .nav-item + .nav-item {
        border-left: 1px solid $white;
    }
}

.glass-references-content,
.reference-screens-content {
    .list-unstyled {
        padding: 0;

        dt {
            list-style-type: none;
        }

        dd {
            font-size: inherit;
        }
    }
    
    @include media-breakpoint-up(lg) {
        .product-image {
            max-width: 250px;
        }
    }
}

.glass-references-content {
    .categorie {
        display: block;
        max-width: max-content;
    }

    .sun,
    .rainbow,
    .polarisation {
        display: inline-block;
    
        img {
            max-width: 20px;
        }
    }
}