@import "../variables";

.checkout-and-applepay {
    .dw-apple-pay-button,
    .dw-apple-pay-button:hover {
        border-radius: 0 !important;
        cursor: pointer;
        margin: 0;
        padding: 0.375rem 0.75rem !important;
        width: 100%;
    }

    .btn {
        margin: 0;
        padding: 1rem;
        width: 100%;

        & + .dw-apple-pay-button {
            margin-left: 1rem;
        }
    }
}

.dw-apple-pay-button.apple-pay-cart,
.dw-apple-pay-button.apple-pay-cart:hover {
    border-radius: $border-radius;
    padding: 0.5em 1em;
}

.dw-apple-pay-button.apple-pay-pdp,
.dw-apple-pay-button.apple-pay-pdp:hover {
    border-radius: $border-radius;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    padding: 0.5em 1em;
}

isapplepay.btn {
    display: none !important;
}

.add-to-cart,
.add-to-cart-global {
    flex-basis: 0;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media-breakpoint-down(xs) {
        max-width: 100%;
    }
}

.applepay-tab-wrapper {
    display: none;
}

body.apple-pay-enabled .applepay-tab-wrapper {
    display: block;
}
