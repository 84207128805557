.content-tile {
    .content-text {
        .content-title {
            text-align: center;

            @include font-size(18px);
            @include mt(3);
        }
    }

    .content-image-wrapper {
        position: relative;
    }

    .content-overlay {
        background-color: rgba($black, 0.6);
        bottom: 0;
        color: $white;
        display: flex;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity 0.2s linear;

        @include font-size(14px);
        @include p(3);
    }

    &:hover {
        .content-overlay {
            opacity: 1;
        }
    }
}
