@import "../variables";
@import "~base/components/pricing";

.price {
    .strike-through {
        color: $strikethrough-text-color;
        text-decoration: line-through;

        @include mr(1);
    }

    .sales {
        text-align: center;
        @include media-breakpoint-down(xs){
            text-align: left;
        }
    }

    .value, .strike-through, .formatted-price{
        @include media-breakpoint-down(xs){
            margin-right: 0;
        }
    }

    .percentage{
        background-color: $price-percent-bg;
        padding-left: 10px;
        padding-right: 10px;
        color: $price-percent-color;
    }
}
