.hero-banner {
    overflow: hidden;
    position: relative;

    .hero-banner-image-wrapper {
        width: 100%;
    }

    .banner-image {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;

        &.banner-image-filter {
            filter: brightness(70%);
        }
    }

    .hero-banner-overlay-wrapper {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .hero-banner-overlay {
        height: 100%;
    }


    .hero-banner-overlay-inner {
        color: $default-hero-color;
        padding: 1rem 1rem 4rem 1rem;
    }

    .hero-banner-overlay  {
        &.align-items-center{
            .hero-banner-overlay-inner {
                padding: 1rem 1rem 1rem 1rem;
            }  
        }
    }

    .banner-subheading {
        @include mb(3);
    }

    .btn {
        border-radius: 3rem;
        font-size: 0.875rem;
        padding: 0.5rem 2rem;
    }

    .btn-square {
        border-radius: 0;
    }
}
