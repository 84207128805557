.product-number-rating {
    margin-left: 2px;
}
.ratings {
    color: $product-rating;

    @include font-size(11px);

    .yotpo-stars {
        margin-right: 1px;
    }

    .fa-stack {
        height: 1rem;
        line-height: 1rem;
        vertical-align: -0.24999999rem;
        width: 1rem;
    }

    .fa-star.fa-inverse {
        color: $product-rating-empty;
    }
}
