@import "../variables";
@import "../utilities/mixins";
@import "quickView";

.primary-images {
    .carousel {
        img {
            height: 100%;
            width: 100%;
        }
    }
}

.experience-assets-productTile .product {
    @include media-breakpoint-down(md) {
        width: 100%;
    }
    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}
.product {
    background-color: $product-tile-bg;
    display: flex;
    margin-bottom: 0;
    position: relative;
    transition: box-shadow 0.2s;
    width: 100%;

    @include media-breakpoint-down(md) {
        @include mx(3);
        width: calc(50% - 2rem);
        min-height: 23.4375em;
    }

    @include media-breakpoint-down(sm) {
        @include mx(2);
        width: calc(50% - 1rem);
        min-height: 19.6875em;
    }

    @include media-breakpoint-down(xs) {
        min-height: 13.4375em;
        &.category-promo {
            width: 100%;
        }
    }

    .product-tile {
        width: 100%;
    }

    .tile-body {
        padding: 0.625em 0.5rem 1.5em;
        align-items: var(--custom-product-tile-content-alignment, start);

        & > div {
            @include mb(2);
        }

        .price {
            color: $product-tile-regular-price;
            font-weight: $font-weight-bold;
            margin-bottom: 0;
            font-size: var(--custom-product-tile-price-size, 15px);

            @include media-breakpoint-down(md) {
                font-size: var(--custom-product-tile-price-size-md-down, 1.0625em);
            }

            @include media-breakpoint-down(sm) {
                font-size: var(--custom-product-tile-price-size-sm-down, 1em);
            }

            @include media-breakpoint-down(xs) {
                .range > .d-flex {
                    flex-direction: column;
                    align-items: center;
                }
            }

            .price-range-separator,
            .price-range-high,
            .price-range-high + .formatted-price {
                display: none;
            }

            .percentage {
                display: none;
            }

            .tiered {
                font-size: var(--custom-product-tile-price-size-tiered, 0.875em);

                .value {
                    font-weight: bold;
                }
            }
        }

        .coming-soon-tile {
            text-align: center;
        }

        .pdp-link {
            display: flex;
            justify-content: var(--custom-product-tile-content-alignment, start);
            line-height: 1.2;
            width: 100%;

            .link {
                color: var(--custom-product-tile-name-color);
                font-family: var(--custom-product-name-font-family);
                font-weight: 900;
                overflow: hidden;
                text-decoration: none;
                text-overflow: ellipsis;
                white-space: nowrap;

                font-size: var(--custom-product-tile-name-size, 15px);

                @include media-breakpoint-down(md) {
                    font-size: var(--custom-product-tile-name-size-md-down, 0.9375em);
                }

                @include media-breakpoint-down(sm) {
                    font-size: var(--custom-product-tile-name-size-sm-down, 0.8125em);
                }
            }
        }
    }

    .product-tile-label-container {
        .product-tile-label {
            border-radius: 0px;
            border: 1px solid var(--custom-product-tile-label-border-color, var(--custom-body-text-color));
            font-size: 0.8125em;
            font-weight: bold;
            padding: var(--custom-product-tile-label-pt, 0px) var(--custom-product-tile-label-px, 0.8rem) var(--custom-product-tile-label-pb, 0px);
            line-height: 1.1rem;
            color: var(--custom-product-tile-label-text-color, var(--custom-color-red));
            text-transform: uppercase;
        }
    }

    .image-container {
        position: relative;
        display: flex;
        flex-direction: column;

        .badges {
            left: calculate-rem(5px);
            position: absolute;
            top: calculate-rem(5px);

            @include media-breakpoint-down(xs) {
                flex-direction: column;
                transform: scale(0.7);
            }
        }

        .badge {
            border-radius: 50%;
            height: calculate-rem(35px);
            padding: calculate-rem(10px) 0;
            text-transform: uppercase;
            width: calculate-rem(35px);
            @include font-size(13px);

            @include media-breakpoint-up(sm) {
                & + .badge {
                    @include ml(2);
                }
            }

            @include media-breakpoint-down(xs) {
                & + .badge {
                    @include mt(2);
                }
            }

            &.new {
                background-color: $product-tile-badge-new-bg;
                color: $product-tile-badge-new-text;
            }

            &.sale {
                background-color: $product-tile-badge-sale-bg;
                color: $product-tile-badge-sale-text;
            }
        }

        @include stickers(40px);

        .quickview {
            margin-top: -2.7rem;
            margin-bottom: 0.5rem;
            z-index: 1;
            opacity: 0;
            transition: opacity 0.2s;
        }

        .link {
            display: block;
        }

        .image-wrapper {
            align-items: center;
            display: flex;
            min-height: 10rem;

            filter: var(--custom-product-tile-image-filter, brightness(0.96));
            background-color: var(--custom-product-tile-image-background-color, rgba(0, 0, 0, 0.04));
            display: flex;
            aspect-ratio: var(--custom-product-tile-image-ratio, 1/1);
            padding: 10%;
            overflow: hidden;

            @include media-breakpoint-down(md) {
                min-height: 6em;
            }
            &:hover {
                .worn-image {
                    opacity: 1;
                }
                ~ [data-hide-on-worn-image="true"] {
                    opacity: 0;
                }
            }
        }

        .tile-image {
            width: 100%;
        }
        .worn-image {
            width: 100%;
            position: absolute;
            z-index: 1;
            opacity: 0;
            transition: opacity 0.3s ease;
            left: 0;
        }
        &:hover {
            .color-swatches {
                .swatches {
                    opacity: 1;
                }
                .tile-labels-container {
                    opacity: 0;
                }
            }
        }
    }

    .color-swatches {
        position: relative;
        justify-content: var(--custom-product-tile-content-alignment, start);
        display: flex;
        width: fit-content;
        padding: .5rem .5rem 0;
        width: 100%;
        .swatches {
            position: absolute;
            opacity: 0;
            display: flex;
            transition: opacity 0.3s ease;
            gap: 0.5rem;
            a {
                text-decoration: none;
                display: flex;
                align-items: center;
            }
            .view-pdp-link svg {
                width: 2rem;
            }
        }
        .tile-labels-container {
            min-height: 3rem;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            width: 100%;
            align-items: var(--custom-product-tile-content-alignment, start);
            justify-content: end;

            .swatches-label {
                flex: 0 0 auto;
                display: flex;
                font-size: 0.9rem;
                color: var(--custom-color-gray-600);
            }
        }
    }

    .swatch-circle {
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        background-color: $gray-100; // in case the image is not loaded, have something to show
        max-height: 3rem;
        max-width: 3rem;
    }

    @include media-breakpoint-up(md) {
        .slick-slider &:hover {
            box-shadow: none;
        }

        &:hover {
            z-index: 2;

            .image-container {
                &::before {
                    opacity: 0.3;
                }
            }

            .quickview {
                opacity: 1;
            }
        }
    }
}

#chooseBonusProductModal {
    .modal-footer {
        .container {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
        }
    }

    .select-cbp-container {
        margin-bottom: auto;
        margin-top: auto;
    }

    .product-name-wrapper {
        width: 100%;
    }

    .bonus-quantity,
    .bonus-option {
        margin-top: 0.938em;
    }

    .bonus-quantity-select {
        min-width: 5em;
    }

    .select-bonus-product {
        margin-top: 1em;
    }

    .selected-pid {
        border: 1px solid gray;

        .bonus-product-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .bonus-product-price {
        margin-top: 1em;
        text-align: center;
    }
}
