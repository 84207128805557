@import "../variables";

.add-to-wishlist-messages {
    left: 50%;
    position: fixed;
    top: 15%;
    transform: translate(-50%, -50%);
    z-index: $header-above;
}

.add-to-wishlist-alert {
    animation: fade 5s linear forwards;
    box-shadow: 1px 1px 5px grey;
    padding: 1em;

    @keyframes fade {
        0% {
            opacity: 0;
        }

        10% {
            opacity: 1;
        }

        90% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    &.show {
        display: block;
    }
}

.wishlistTile {
    color: $product-wishlist-heart;
    position: absolute;
    right: calculate-rem(10px);
    top: calculate-rem(10px);
}
