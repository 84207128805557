.iti {
    display : block;
    input, input[type=text], input[type=tel]{
        padding-left : 36px;
        padding-right : 0px;
    }
}
.iti__flag-container {
    left : 0;
    width : 36px;
}
.iti__flag {
    background-image: url(../images/flags/flags.png);
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {background-image: url("../images/flags/flags@2x.png");}
}