$font-url: '../fonts/';

@font-face {
	font-family: 'SpaceGrotesk';
	src: url(#{$font-url}SpaceGrotesk-Bold.ttf) format('truetype'),
	url(#{$font-url}SpaceGrotesk-Bold.woff2) format('woff2');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: 'SpaceGrotesk';
	src: url(#{$font-url}SpaceGrotesk-Light.ttf) format('truetype'),
	url(#{$font-url}SpaceGrotesk-Light.woff2) format('woff2');
	font-weight: 100;
	font-style: normal;
}
@font-face {
	font-family: 'SpaceGrotesk';
	src: url(#{$font-url}SpaceGrotesk-Medium.ttf) format('truetype'),
	url(#{$font-url}SpaceGrotesk-Medium.woff2) format('woff2');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'SpaceGrotesk';
	src: url(#{$font-url}SpaceGrotesk-Regular.ttf) format('truetype'),
	url(#{$font-url}SpaceGrotesk-Regular.woff2) format('woff2');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'SpaceGrotesk';
	src: url(#{$font-url}SpaceGrotesk-SemiBold.ttf) format('truetype');
	font-weight: 600;
	font-style: normal;
}

/* Fonts coming from Page Designer wysiwyg */

// Mistral
@font-face {
	font-family: 'Mistral';
	src: url(#{$font-url}Mistral.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
}

// Gotham
@font-face {
	font-family: 'Gotham';
	src: url("#{$font-url}Gotham-Medium.otf") format('opentype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Gotham';
	src: url("#{$font-url}Gotham-Bold.otf") format('opentype');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Gotham';
	src: url("#{$font-url}Gotham-Italic.otf") format('opentype');
	font-weight: normal;
	font-style: italic;
}
@font-face {
	font-family: 'Gotham';
	src: url("#{$font-url}Gotham-BoldItalic.otf") format('opentype');
	font-weight: 600;
	font-style: italic;
}

// Gotham Narrow
@font-face {
	font-family: 'Gotham Narrow';
	src: url(#{$font-url}GothamNarrow-Medium.otf) format('opentype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Gotham Narrow';
	src: url("#{$font-url}GothamNarrow-Bold.otf") format('opentype');
	font-weight: 600;
	font-style: normal;
}

// Gotham Condensed
@font-face {
	font-family: 'Gotham Condensed';
	src: url(#{$font-url}GothamCondensed-Medium.otf) format('opentype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Gotham Condensed';
	src: url("#{$font-url}GothamCondensed-Bold.otf") format('opentype');
	font-weight: 600;
	font-style: normal;
}

// Retro Gaming
@font-face {
	font-family: 'Retro Gaming';
	src: url("#{$font-url}RetroGaming.ttf") format('truetype');
	font-weight: normal;
	font-style: normal;
}

// Space Mono
@font-face {
	font-family: 'Space Mono';
	src: url("#{$font-url}SpaceMono-Regular.ttf") format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Space Mono';
	src: url("#{$font-url}SpaceMono-Bold.ttf") format('truetype');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Space Mono';
	src: url("#{$font-url}SpaceMono-Italic.ttf") format('truetype');
	font-weight: normal;
	font-style: italic;
}
@font-face {
	font-family: 'Space Mono';
	src: url("#{$font-url}SpaceMono-BoldItalic.ttf") format('truetype');
	font-weight: 600;
	font-style: italic;
}

// Acid Grostesk
@font-face {
	font-family: 'Acid Grotesk';
	src: url("#{$font-url}AcidGrotesk-Regular.otf") format('opentype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Acid Grotesk';
	src: url("#{$font-url}AcidGrotesk-Bold.otf") format('opentype');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Acid Grotesk';
	src: url("#{$font-url}AcidGrotesk-Italic.otf") format('opentype');
	font-weight: normal;
	font-style: italic;
}
@font-face {
	font-family: 'Acid Grotesk';
	src: url("#{$font-url}AcidGrotesk-BoldItalic.otf") format('opentype');
	font-weight: 600;
	font-style: italic;
}

// The Rambler
@font-face {
	font-family: 'The Rambler';
	src: url("#{$font-url}TheRambler.otf") format('opentype');
	font-weight: normal;
	font-style: normal;
}

// Another Monday
@font-face {
	font-family: 'Another Monday';
	src: url("#{$font-url}AnotherMonday.otf") format('opentype');
	font-weight: normal;
	font-style: normal;
}

// Papernotes
@font-face {
	font-family: 'Papernotes';
	src: url("#{$font-url}Papernotes-Regular.otf") format('opentype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Papernotes';
	src: url("#{$font-url}Papernotes-Bold.otf") format('opentype');
	font-weight: 600;
	font-style: normal;
}

// Matrice
@font-face {
	font-family: 'Matrice';
	src: url("#{$font-url}Matrice-Regular.otf") format('opentype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Matrice';
	src: url("#{$font-url}Matrice-Bold.otf") format('opentype');
	font-weight: 600;
	font-style: normal;
}