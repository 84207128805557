
.form-group {
    &.required .form-control-label{
        &:after {
            content: "*";
            color: $danger;
        }
        &:before {
            content: " " !important;
        } 
    }
    .input-group ~ .invalid-feedback, .iti.invalidField ~ .invalid-feedback {
        display: block;
    }

    .custom-control-input.is-invalid ~ .custom-control-label.no-color-warning {
        color: $gray-900;
    }
    .text-decoration-underline {
        text-decoration: underline;
    }
}

.form-row {
    .required .form-control-label::after {
        content: "*";
        color: $danger;
    }
}

.force-remove-required .form-control-label::after {
    content: " " !important;
}

.example-phone, 
.example-legend, 
.example-zip,
.no-po-box {
    font-size: 0.75rem;
}
