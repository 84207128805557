@import "navigation";
@import "mobile-navigation";

.page {
    .site-header {
        position: fixed;
        z-index: 11;
    }
}

.site-header {
    width: 100%;

    .icon {
        color: $site-main-menu-icon;
    }

    .header-account-section a {
        color: $site-menu-item-link;
    }

    .service-cloud-banner {
        background-color: $danger;
        color: $white;
        height: 40px;
    }

    .navbar {
        height: 100%;
    }

    .navbar-brand, .inner_header_content {
        display: flex;
    }

    .main-nav {
        margin-bottom: -1px;
    }

    .minicart-link {
        position: relative;

        .minicart-quantity:not(:empty) {
            border: 1px solid $site-menu-item-text;
            border-radius: 50%;
            width: 1.3em;
            height: 1.3em;
            position: absolute;
            display: flex;
            right: -1.1em;
            top: -0.6em;
            background: $body-background;
            align-items: center;
            justify-content: center;
            padding: 0.7em;
            font-size: .9rem;
        }
    }

    .minicart-total {
        align-items: center;
        background-color: $site-cart-background;
        display: flex;
        height: calculate-rem(40px);
        min-width: 60px;
        justify-content: center;
        padding-bottom: 0.125rem;
        width: calculate-rem(40px);
        
        .minicart-link {
            color: $site-menu-item-text;
        }
    }

    .mobile-search_btn {
        display: inline-block;

        @include p(3);
    }

    @include media-breakpoint-down(md) {
        .minicart-total {
            background-color: $site-cart-mobile-background;
            color: $site-menu-item-text;
            height: 100%;
        }
        .right-nav {
            position: static;
        }
    }

    .preheader {
        position: relative;
        z-index: -2;
        background-color: $site-preheader-bg;

        .country-selector {
            cursor: pointer;

            .dropdown-menu {
                border: 0;
                border-radius: 0;
                box-shadow: $default-box-shadow;
                margin: 0;
            }

            .dropdown-item {
                color: $site-menu-item-text !important;
            }

            &:hover {
                .dropdown-menu {
                    display: block;
                }
            }
        }

        .country-selector_button {
            color: $site-preheader-text;
        }

        a,
        .country-selector,
        .minicart-total {
            color: $site-preheader-text;
            font-size: 0.75rem;
            text-transform: uppercase;
        }
    }

    .search-field {
        border-left: 0;
    }

    .dropdown-toggle {
        &::after {
            border: 0;
        }
    }

    .nav-link {
        padding-left: 0 !important;
        padding-right: 0 !important;

        @include font-size(12px);

        @include media-breakpoint-up(md) {
            @include font-size(14px);
        }
    }

    .right-nav-minified {
        display: none;
    }

    .store-switcher-wrapper {
        display: flex;
        flex-wrap: wrap;
        max-height: 170px;
        background-color: $black;
        letter-spacing: .5px;
        animation: fadeIn 1.4s;
        @include p(20px);

        @keyframes fadeIn {
            0% {
                opacity: 0;
            }
    
            100% {
                opacity: 1;
            }
        }
    }
    .store-switcher-message {
        order: 1;
        width: 90%;
        color: $white;
        line-height: 16px;
        @include pr(40px);
        @include font-size(12px);
    }
    .store-switcher-close {
        color: $white;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 5px;
        @include p(10px);
        @include font-size(22px);
    }
    .store-switcher-storeswitcher {
        order: 3;
        position: relative;
        width: 70%;
        @include mt(10px);
        .country-selector_button {
            display: flex;
            align-items: center;
            height: 45px;
            border: 1px solid $gray-500;
            border-right: none;
            border-radius: 2px;
            cursor: pointer;
            z-index: 999999;
            color: $white;
            @include font-size(14px);
            .flag-icon {
                @include px(15px);
            }
            .fa-angle-down {
                display: block;
                position: absolute;
                right: 10px;
                top: 0;
                bottom: 0;
                height: 24px;
                @include m(auto);
                @include font-size(18px);
            }
        }
        .dropdown-menu {
            width: 100%;
            z-index: 10008;
            @include p(0);
            li {
                .dropdown-item {
                    background-color: $site-page-bg;
                    border: 1px solid $gray-500;
                    border-top: none;
                    @include p(9px 40px 9px 6px);
                    @include font-size(14px);
                }
            }
            &.show {
                display: block;
            }
        }
    }
    .store-switcher-ok-btn {
        order: 4;
        width: 30%;
        height: 45px;
        background-color: $white;
        border: none;
        cursor: pointer;
        color: $black;
        @include mt(10px);
        @include px(5px);
        @include font-size(14px);
    }
    @include media-breakpoint-up(sm) {
        .store-switcher-storeswitcher {
            width: 78%;
        }
        .store-switcher-ok-btn {
            width: 22%;
        }
    }
    @include media-breakpoint-up(lg) {
        .store-switcher-wrapper {
            flex-wrap: nowrap;
            align-items: center;
            @include p(20px 45px);
        }
        .store-switcher-message {
            width: 42%;
            @include font-size(14px);
        }
        .store-switcher-close {
            right: 20px;
            top: 27px;
            @include p(0);
        }
        .store-switcher-storeswitcher {
            order: 2;
            width: 280px;
            @include mt(0);
            @include mr(60px);
            .country-selector_button {
                border-right: 1px solid $gray-500;
            }
        }
        .store-switcher-ok-btn {
            order: 3;
            width: 110px;
            @include mt(0);
        }
    }
    @include media-breakpoint-up(xl) {
        .store-switcher-message {
            width: 50%;
            letter-spacing: 0.6px;
        }
        .store-switcher-storeswitcher {
            @include mr(110px);
        }
    }
    
}

.site-header_slim {
    min-height: 56px;
    @include media-breakpoint-up(md) {
        min-height: 110px;
    }
    .main-menu {
        border-bottom: 0;
    }
}

.promo-banner {
    @include font-size(12px);

    &,
    & .icon {
        color: $site-preheader-text;
    }
}

.permanent-header-banner {
    background-color: $site-preheader-bg;
    @include font-size(12px);
    padding: 1em 0 .7em;

    &, a, .icon {
        color: $site-preheader-text;
        text-decoration: none;
    }
}

.main-menu {
    position: relative;

    &,
    > .navbar {
        background-color: $site-main-menu-bg;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
    }
}

@include media-breakpoint-down(lg) {
    .navbar-brand {
        .logo {
            display: none;
            max-width: initial;
            width: $logo-width-small;
        }
        .logo-alt {
            display: block;
            max-width: initial;
            width: $logo-width-small;
        }
    }
    .permanent-sticky {
        .navbar-brand{
            .logo, .svg-logo-container {
                max-height: $logo-max-height-mobile;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .non-sticky-header {
        .navbar-brand {
            .logo,
            .logo-alt {
                width: $logo-width-lg;
                height: auto;
            }

            .logo {
                display: none;
            }
        }
    }

    .site-header {
        transform: translateY(0);
        transition: transform 0.2s $default-cubic-bezier;

        .main-nav,
        .main-nav,
        .navbar-nav,
        .navbar-inner {
            height: 100%;
        }

        .main-menu {
            transition: height 0.2s $default-cubic-bezier;
        }

        .right-nav-minified {
            display: none;

            .user-message {
                display: none;
            }

            .minicart-total {
                padding: 0 !important;
            }
        }

        .right-nav {
            display: flex;
            justify-content: center;
            padding-top: .5em;

            a {
                color: $secondary;
            }

            .blog-button {
                a {
                    font-size: 0.8rem;
                    color: $tertiary;
                }
            }
        }

        .account-greeting-header {
            > i {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    width: 0.5em;
                    height: 0.5em;
                    background: #008000;
                    border: 1px solid $body-background;
                    border-radius: 50%;
                    bottom: 0px;
                    right: -0.3em;
                }
            }
        }
    }

    .minify-header {
        &.service-cloud-agent {
            .site-header:not(.site-header_slim) {
                transform: translateY(-80px);
            }
        }

        .non-sticky-header {
            .navbar-inner {
                @include p(0);
            }

            .navbar-brand,
            .header-storelocator-section,
            .header-account-section,
            .header-blog-button-section,
            .header-minicart-section {
                display: none !important;
            }
        }

        .sticky-hide {
            display: none !important;
        }

        .sticky-show {
            display: flex !important;
        }

        .sticky-header {
            &.blog-enabled {
                .navbar-brand,
                .sticky-header-minicart,
                .header-blog-button-section {
                    @include make-col(1);
                }
                .header-nav {
                    @include make-col(8);
                }
            }
            .navbar-brand {
                justify-content: center;
            }

            .navbar-brand,
            .sticky-header-minicart {
                @include make-col(2);
                @include p(0);

                .logo {
                    display: block;
                    max-width: $logo-max-width;
                }
            }

            .navbar-brand {
                @include make-col(1);
            }

            .header-nav {
                @include make-col(9);
            }

            .sticky-header-minicart {
                .minicart-total {
                    height: 100%;
                }

                @include media-breakpoint-down(lg) {
                    .minicart-quantity {
                        text-align: center;
                    }
                }
                
            }
            
        }

        .right-nav {
            @include make-col(12);
            padding-top: 0px;
        }

        .site-search {
            @include m(0);
            left: 0;
            position: fixed;
            right: 0;
            top: 0;
            transform: translateY(0);
            transition: transform 0.5s $default-cubic-bezier, z-index .5s ease;
            width: 100%;
            z-index: -1;

            .site-search-close {
                display: block;
            }

            .suggestions {
                display: none !important;
                @include make-col(12);
                width: 100%;
                left: 0;
            }
        }

        &.site-search-active {
            .site-search {
                transform: translateY(52px);
                z-index: 1;

                .suggestions {
                    display: block !important;
                }
            }
        }

        .site-header:not(.site-header_slim) {
            transform: translateY(0);

            .right-nav-minified {
                align-items: center;
                display: flex;
                justify-content: center;
            }
        }

        .minicart-total {
            background-color: $site-cart-background;
        }

        .main-menu {
            height: auto;
        }

        .main-nav {
            margin-bottom: -8px;
            margin-right: auto;
            width: 100%;
        }

        .navbar-nav {
            justify-content: center;
            flex-direction: row;
        }
    }
    .permanent-sticky {
        .sticky-header {
            .navbar-brand,
            .sticky-header-minicart {
                .logo {
                    max-height: $logo-max-height;
                }
            }
        }
    }
}

@include media-breakpoint-up(xxl) {
    .non-sticky-header {
        .navbar-brand {
            a {
                margin-bottom: -72px;
                z-index: 1;
                display: flex;
                align-items: center;
            }

            .logo,
            .logo-alt {
                width: $logo-width-xxl;
                height: auto;
            }
        }
    }
}

.suggestions{
    .price-range-separator,
    .price-range-high,
    .price-range-high + .formatted-price {
        display: none;
    }
}


.blog-button{
    color: $tertiary;
    padding: 5px 10px;
    font-weight: bold;
    background-color: $secondary;
    a {
        font-size: 0.8rem;
        color: $tertiary;
        line-height: 1.4rem;
    }
}

@media (max-width: 767.98px) { 
    .site-header .right-nav {
        padding-left: 0;
        padding-right: 0;
    }
}
