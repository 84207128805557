@import "../utilities/mixins";

*:focus {
    outline: none !important;
}

html,
body {
    height: 100%;
}

.checkout,
.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
}

.site-footer {
    margin-top: auto;
}

a {
    display: inline-block;
}

button,
a {
    &.disabled {
        cursor: not-allowed;
    }
    &::disabled {
        cursor: not-allowed;
    }
}

img {
    max-width: 100%;
}

.img-fluid {
    width: 100%;
}

.main-content {
    overflow-x: hidden;

    @include pb(5);
}
.permanent-sticky {
    .main-content {
        @include pb(0);
        overflow-y: hidden;
    }
}

.logo-not-found {
    max-width: 80px;
}

@include media-breakpoint-up(lg) {
    .service-cloud-agent {
        .main-content {
            padding-top: 180px;
        }
    }
    .main-content {
        padding-top: 146px;
    }

    .banner-promo_active .main-content {
        padding-top: 180px;
    }
    .permanent-sticky {
        &.service-cloud-agent {
            .main-content {
                padding-top: 174.6px;
            }
        }
        .main-content {
            padding-top: 54.4px;
        }
        &.banner-promo_active .main-content {
            padding-top: 174.6px;
        }
    }
}

@include media-breakpoint-down(md) {
    .service-cloud-agent {
        .main-content {
            padding-top: 90px;
        }
    }
    .main-content {
        padding-top: 50px;
    }
    .banner-promo_active .main-content {
        padding-top: 90px;
    }
    .permanent-sticky {
        &.service-cloud-agent {
            .main-content {
                padding-top: 221px;
            }
        }
        .main-content {
            padding-top: 59.2px;
        }
        &.banner-promo_active .main-content {
            padding-top: 221px;
        }
    }
}

a {
    transition: opacity 0.2s ease-out;

    &:hover {
        text-decoration: none;
    }
}

dl {
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
}
dt {
    clear: both;
    float: left;
    font-weight: $font-weight-bold;
    margin-right: 5px;
    padding: 0 0.3rem 0 0;
}
dd {
    float: left;
    margin-bottom: 0.25rem;
}

.section-header {
    text-align: center;
    font-size: 3rem;
    line-height: 1.2;
    @include mb(5);
}

.modal-background {
    background-color: rgba(0, 0, 0, 0.75);
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 0.2s, visibility 0ms linear 0.2s, z-index 0ms linear 0.2s;
    visibility: hidden;
    z-index: -1;

    &.open {
        opacity: 1;
        transition: opacity 0.2s, visibility 0ms;
        visibility: visible;
        z-index: 2;
    }
}

.checklist {
    list-style-type: none;
    padding: 0;
    @include my(10px);
    li {
        line-height: 1.125rem;
        font-weight: $font-weight-normal;
        font-variant: normal;
        text-transform: none;
        position: relative;

        @include font-size(13px);
        @include ml(16px);
        &:before {
            position: absolute;
            top: 5px;
            left: -16px;
            width: 10px;
            min-width: 10px;
            height: 5px;
            border: 2px solid $gray-900;
            border-radius: $border-radius;
            content: "";
            border-top-width: 0;
            border-right-width: 0;
            transform: rotate(-45deg);

            @include mt(1px);
        }
    }
}

.badge-round {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	background-color: $primary;
	> span {
		max-width: 100%;
		color: color-yiq($primary);
	}
	&.lg {
		width: 70px;
		height: 70px;
	}
}

.grecaptcha-badge {
    visibility: hidden;
}

// generate order classes for a maximum of 30 items
@include flex-order-classes(30);
