@include media-breakpoint-up(lg) {
    .level-2-nav-container {
        width: 100%;
    }

    .level-1 > .nav-item {
        border-bottom: 4px solid transparent;
        margin-bottom: 1px; // to compensate for the border
        padding: 0.5em 2rem;

        &:hover {
            border-bottom: 4px solid $site-menu-item-header;
        }

        > .nav-link {
            font-family: $site-menu-level1-font;
            padding: 0.5em 2rem;
        }

        .nav-link.non-clickable-link {
            color: $non-clickable-link-color;
        }

        .nav-link.non-clickable-link:hover {
            color: $non-clickable-link-color;
        }

    }

    .minify-header {
        .level-1 > .nav-item {
            padding: 0.5em 0.7rem;
        }
    }

    .level-2 .container > ul > .nav-item {
        > .nav-link {
            font-family: $site-menu-level2-font;
        }
    }

    .level-3 > .nav-item {
        > .nav-link {
            font-family: $site-menu-level3-font;
        }

        > .nav-link:hover {
            color: $site-menu-level-3-hover-color;
        }

        > .non-clickable-link:hover {
            color: $non-clickable-link-color;
        }
    }

    .header-nav {
        height: inherit;
    }

    .mega-menu_open {
        border-bottom: 4px solid $site-menu-item-header;
    }

    .navbar-nav > li {
        font-weight: $font-weight-bolder;
        text-transform: uppercase;
    }

    .mega-menu__item {
        border-bottom: 4px solid transparent;
        cursor: default;

        .non-clickable {
            cursor: default;
        }

        .mega-menu__sub-wrapper {
            background: $site-mobile-nav-bg;
            border-bottom: 1px solid $site-main-menu-border;
            box-shadow: $default-box-shadow;
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            text-align: left;
            top: 100%;
            transition: opacity 0.2s $default-cubic-bezier 0.2s, $default-cubic-bezier 0.2s $default-cubic-bezier 0.2s,
                border-bottom 0.2s $default-cubic-bezier 0.2s;
            visibility: hidden;
            z-index: 2;

            .menu-items-container{
                width: 100%;

                .menu-icon-img{
                    max-width: 60px;
                    max-height: 30px;
                }

                .menu-category-name{
                    font-family: var(--custom-menu-category-name, var(--font-family-sans-serif, inherit));
                    margin-top: 25%;
                    font-size: 2.125rem;
                    line-height: 1.2;
                    margin-bottom: 0.5rem;
                }
            }
        }
    }

    .mega-menu_open > .mega-menu__sub-wrapper {
        opacity: 1;
        visibility: visible;
    }
    .menu-default-template{
        .mega-menu__sub {
            max-height: 350px;
        }
    }

    .menu-main-template{
        .mega-menu__sub {
            height: 100%;
        }
    }


    .mega-menu__block {
        width: 200px;
    }

    .lvl-2-link {
        font-family: var(--custom-menu-category-name, var(--font-family-sans-serif, inherit));
    }

    .mega-menu__block-header {
        color: $site-menu-item-header !important;
        font-size: 1rem;
        font-weight: var(--custom-menu-category-name-weight, #{$font-weight-bold}) !important;
    }

    .mega-menu__block-item {
        .nav-link {
            color: $site-menu-item-text;
            font-weight: 400;
            padding: 0;
            text-transform: none;
        }
    }

    .mega-menu-slot {
        &.category-menu-left {
            @include pr(5);
        }

        &.category-menu-right {
            @include pl(5);
        }
    }

    .flyout-menu-slot {
        margin-top: 1.5em;
    }
}
@include media-breakpoint-up(xl) {
    .minify-header {
        .level-1 > .nav-item {
            padding: 0.5em 1rem;
        }
    }
}

.header-menu-padding-small {
    .minify-header {
        .sticky-header {
            .level-1 > .nav-item {
                padding: 0.6em 0.4rem;
            }
        }
    }
    @include media-breakpoint-up(sm) { 
        .sticky-header {
            .level-1 > .nav-item {
                padding: 0.6em 0.4rem !important;
            }
        }
    }
    @include media-breakpoint-up(md) { 
        .sticky-header {
            .level-1 > .nav-item {
                padding: 0.6em 0.4rem !important;
            }
        }
    }
    @include media-breakpoint-up(lg) { 
        .sticky-header {
            .level-1 > .nav-item {
                padding: 0.65em 0.4rem !important;
            }
        }
        .site-header .nav-link {
            font-size: 0.65rem !important;
        }
        .blog-button {
            padding: 3px 10px 7px 10px;
            a {
                font-size: 0.65rem;
            }
        }
    }
    @media (min-width: 1100px) {
        .site-header .nav-link {
            font-size: 0.7rem !important;
        }
        .blog-button {
            padding: 3px 10px 7px 10px;
            a {
                font-size: 0.7rem;
            }
        }
    }
    @include media-breakpoint-up(xl) { 
        .sticky-header {
            .level-1 > .nav-item {
                padding: 0.4em 0.45rem !important;
            }
        }
        .site-header .nav-link {
            font-size: 0.8rem !important;
        }
        .blog-button {
            padding: 6px 10px 6px 10px;
            a {
                font-size: 0.8rem;
            }
        }
    }
    @media (min-width: 1350px) {
        .site-header .nav-link {
            font-size: 0.9rem !important;
        }
        .blog-button {
            padding: 6 10px 6px 10px;
            a {
                font-size: 0.9rem;
            }
        }
    }
}

.header-menu-padding-medium {
    .minify-header {
        .sticky-header {
            .level-1 > .nav-item {
                padding: 0.6em 0.9rem;
            }
        }
    }
    @include media-breakpoint-up(sm) { 
        .sticky-header {
            .level-1 > .nav-item {
                padding: 0.6em 0.9rem !important;
            }
        }
    }
    @include media-breakpoint-up(md) { 
        .sticky-header {
            .level-1 > .nav-item {
                padding: 0.6em 0.9rem !important;
            }
        }
    }
    @include media-breakpoint-up(lg) { 
        .sticky-header {
            .level-1 > .nav-item {
                padding: 0.65em 0.9rem !important;
            }
        }
        .site-header .nav-link {
            font-size: 0.65rem !important;
        }
        .blog-button {
            padding: 3px 10px 7px 10px;
            a {
                font-size: 0.65rem;
            }
        }
    }
    @media (min-width: 1100px) {
        .site-header .nav-link {
            font-size: 0.7rem !important;
        }
        .blog-button {
            padding: 3px 10px 7px 10px;
            a {
                font-size: 0.7rem;
            }
        }
    }
    @include media-breakpoint-up(xl) { 
        .sticky-header {
            .level-1 > .nav-item {
                padding: 0.4em 1rem !important;
            }
        }
        .site-header .nav-link {
            font-size: 0.8rem !important;
        }
        .blog-button {
            padding: 6px 10px 6px 10px;
            a {
                font-size: 0.8rem;
            }
        }
    }
    @media (min-width: 1350px) {
        .site-header .nav-link {
            font-size: 0.9rem !important;
        }
        .blog-button {
            padding: 6 10px 6px 10px;
            a {
                font-size: 0.9rem;
            }
        }
    }
}