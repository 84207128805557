:root {
    --custom-color-gray-600: #{$gray-600};
    --custom-color-red: #{$red};
    --custom-body-text-color: #{$body-text};
    --custom-color-featured-2: #{$featured-color-2};
    --custom-color-featured-reverse: #{$featured-color-reverse};
    --font-family-sans-serif: #{$font-family-base};
    --font-family-accent: #{$font-family-accent};
    --custom-menu-category-name: var(--font-family-sans-serif);
    --custom-menu-category-name-weight: #{$font-weight-bold};
    --custom-btn-text-weight: normal;
    --custom-footer-header-font: var(--font-family-sans-serif);
    --custom-mobile-level-1-2-font-family: var(--font-family-sans-serif);
    --custom-mobile-level-3-font-family: var(--font-family-sans-serif);
    --custom-breadcrumb-font-family: var(--font-family-sans-serif);
    --custom-product-name-font-family: var(--font-family-sans-serif);
    --custom-product-tile-name-color: #{$gray-900};
    --custom-add-to-cart-font-weight: normal;
}

// reset css
form {
    margin-block-end: 0;
}

// Titles
h1 {
    font-family: $h1-font-family;
    font-weight: $h1-font-weight;
}
h2 {
    font-family: $h2-font-family;
    font-weight: $h2-font-weight;
}

h3 {
    font-family: $h3-font-family;
    font-weight: $h3-font-weight;
}
h4 {
    font-family: $h4-font-family;
    font-weight: $h4-font-weight;
}
h5 {
    font-family: $h5-font-family;
    font-weight: $h5-font-weight;
}
h6 {
    font-family: $h6-font-family;
    font-weight: $h6-font-weight;
}

.font-family-head {
    font-family: var(--font-family-head), #{$h1-font-family} !important;
}

// Font size classes, usefull in content assets

.font-size-8 {
    @include font-size(8px);
    line-height: 1.2;
}

.font-size-10 {
    @include font-size(10px);
    line-height: 1.2;
}

.font-size-12 {
    @include font-size(12px);
    line-height: 1.2;
}

.font-size-14 {
    @include font-size(14px);
    line-height: 1.2;
}

.font-size-16 {
    @include font-size(16px);
    line-height: 1.2;
}

.font-size-18 {
    @include font-size(18px);
    line-height: 1.2;
}

.font-size-20 {
    @include font-size(20px);
    line-height: 1.2;
}

.font-size-22 {
    @include font-size(22px);
    line-height: 1.2;
}

.font-size-24 {
    @include font-size(24px);
    line-height: 1.2;
}

.font-size-26 {
    @include font-size(26px);
    line-height: 1.2;
}

.font-size-28 {
    @include font-size(28px);
    line-height: 1.2;
}

.font-size-30 {
    @include font-size(30px);
    line-height: 1.2;
}

.font-size-32 {
    @include font-size(32px);
    line-height: 1.2;
}

.font-size-34 {
    @include font-size(34px);
    line-height: 1.2;
}

.font-size-36 {
    @include font-size(36px);
    line-height: 1.2;
}

// utilities
.accent-color {
    color: $accent;
}

.primary-color {
    color: $primary;
}

.secondary-color {
    color: $secondary;
}

.border-left {
    border-left: 1px solid $gray-200;
}

.border-right {
    border-right: 1px solid $gray-200;
}

.border-bottom {
    border-bottom: 1px solid $gray-200;
}

.border-left {
    border-left: 1px solid $gray-200;
}

.border-sm-right + .border-sm-left {
    @include media-breakpoint-up(sm) {
        border-left: 1px solid $gray-200;
    }
}

.border-md-right + .border-md-left {
    @include media-breakpoint-up(md) {
        border-left: 1px solid $gray-200;
    }
}

.border-lg-right + .border-lg-left {
    @include media-breakpoint-up(lg) {
        border-left: 1px solid $gray-200;
    }
}

.border-xlg-right + .border-xlg-left {
    @include media-breakpoint-up(xlg) {
        border-left: 1px solid $gray-200;
    }
}

.object-fit_cover {
    object-fit: cover;
}

// color classes
.gray-100-color {
    color: $gray-100;
}

.gray-200-color {
    color: $gray-200;
}

.gray-300-color {
    color: $gray-300;
}

.gray-400-color {
    color: $gray-400;
}

.gray-500-color {
    color: $gray-500;
}

.gray-600-color {
    color: $gray-600;
}

.gray-700-color {
    color: $gray-700;
}

.gray-800-color {
    color: $gray-800;
}

.gray-900-color {
    color: $gray-900;
}

.bold {
    font-weight: bold;
}

// Breadcrumbs

.breadcrumb {
    font-family: var(--custom-breadcrumb-font-family, var(--font-family-sans-serif));
    .breadcrumb-item {
        a {
            color: $breadcrumbs;
            font-weight: $font-weight-bold;
            text-transform: uppercase;

            @include font-size(13px);
        }
    }

    @include media-breakpoint-down(md) {
        & {
            @include pt(0);
            @include pb(3);
        }
    }
}

// Card
.card {
    @include mb(3);

    .card-header {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 0;
        }
    }
}

// Forms

.col-form-label {
    font-size: 16px;

    &-sm {
        font-size: $font-size-sm;
    }

    &-lg {
        font-size: $font-size-lg;
    }
}

textarea,
textarea.form-control,
input.form-control,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
[type="text"].form-control,
[type="password"].form-control,
[type="email"].form-control,
[type="tel"].form-control,
[contenteditable].form-control {
    box-shadow: none;
	border: 1px solid $gray-300;
    transition: none;

    padding-left: 0.25rem;
    padding-right: 0.25rem;

    &:focus {
		border: 2px solid $black;
    }

    &[disabled],
    &[readonly] {
        border-bottom: 1px dotted #ddd;
        box-shadow: none;
    }

    &[disabled],
    &[disabled]::placeholder {
        color: #ddd;
    }

    &::-ms-clear {
        display: none;
    }
}

.form-check {
    .form-check-input {
        margin-top: 0;
    }
}

.form-control {
    height: calc(1.55em + 1rem);
}

select,
select.form-control {
    appearance: none;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAJ1BMVEVmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmaP/QSjAAAADHRSTlMAAgMJC0uWpKa6wMxMdjkoAAAANUlEQVR4AeXJyQEAERAAsNl7Hf3X6xt0QL6JpZWq30pdvdadme+0PMdzvHm8YThHcT1H7K0BtOMDniZhWOgAAAAASUVORK5CYII=);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 13px;
    box-shadow: none;
    border: 1px solid $gray-300;
    overflow: hidden;
    padding: 0.4rem 1rem 0.4rem 0;
    text-overflow: ellipsis;

    padding-left: 0.25rem;
    padding-right: 0.25rem;
    
    &::-ms-expand {
        display: none;
    }

    &.input {
        &-sm {
            font-size: $font-size-sm;
        }

        &-lg {
            font-size: $font-size-lg;
        }
    }

    &:focus {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAJ1BMVEUhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISF8S9ewAAAADHRSTlMAAgMJC0uWpKa6wMxMdjkoAAAANUlEQVR4AeXJyQEAERAAsNl7Hf3X6xt0QL6JpZWq30pdvdadme+0PMdzvHm8YThHcT1H7K0BtOMDniZhWOgAAAAASUVORK5CYII=);
        background-size: 13px;
        box-shadow: none;
        border: 2px solid $black;
    }

    &[multiple] {
        background: none;
    }
}

.custom-control {
    min-height: 1.5rem;
}

.custom-control-label {
    &::before {
        border: 2px solid $gray-500;
    }
}

.custom-checkbox .custom-control-input:checked {
    ~ .custom-control-label::after {
        transform: scale(1.5);
    }
}

.custom-radio .custom-control-input {
    &:checked {
        ~ .custom-control-label::before {
            background: $white;
        }
        ~ .custom-control-label::after {
            transform: scale(1.125);
            transition: 240ms;
        }
    }
    & {
        ~ .custom-control-label::after {
            transform: scale(0.75);
        }
    }
}

.custom-switch {
    .custom-control-label {
        .active {
            display: none;
        }
        .inactive {
            display: inline;
        }
    }

    .custom-control-input:checked {
        & ~ .custom-control-label {
            .active {
                display: inline;
            }
            .inactive {
                display: none;
            }
        }
    }

    .custom-control-label::before {
        background: $gray-500;
        border: none;
        pointer-events: all;
    }

    .custom-control-label::after {
        background-color: #bbb;
    }

    .custom-control-input:disabled ~ .custom-control-label {
        opacity: 0.7;
    }

    .custom-control-input:disabled ~ .custom-control-label::before {
        background: $gray-500;
    }

    .custom-control-label::after {
        background-color: #fff;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        opacity: 0.38;
    }

    .custom-control-input:checked ~ .custom-control-label::after {
        background-color: $primary;
    }

    .custom-control-input:active:not(:checked) ~ .custom-control-label::before {
        background-color: $gray-500;
    }

    .custom-control-input:active:not(:checked) ~ .custom-control-label::before {
        background-color: $gray-500;
    }
}

.custom-checkbox {
    .custom-control-label {
        &::before {
            background-color: #fff;
            border: 1px solid $checkbox-border-color;
            border-radius: 0;
        }
    }

    .custom-control-input:active {
        & ~ .custom-control-label {
            &::before {
                background-color: #fff;
            }
        }
    }

    .custom-control-input:checked {
        & ~ .custom-control-label {
            &::before {
                background-color: #fff;
            }

            &::after {
                @include fa-icon("\f00c", "regular");
                background-image: none;
                color: $checkbox-check-color;
                padding: 3px;
                text-align: center;

                @include font-size(8px);
            }
        }
    }
}

.custom-select {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 46.7 36'%3E%3Cpath fill='%393939' d='M23.5 33.333L47 0H0z'/%3E%3C/svg%3E");
    background-position: right 0.25rem center;

    &:focus {
        border: 0;
    }
}

.form-control,
.form-control:focus {
    font-size: 0.8125rem;
    outline: none;
    outline-width: 0 !important;
}

.form-control {
    .was-validated &:valid,
    &.is-valid,
    &.is-valid:focus {
        box-shadow: none;
        border: 2px solid $success;
    }

    .was-validated &:invalid,
    &.is-invalid,
    &.is-invalid:focus {
        box-shadow: none;
        border: 2px solid $danger;
    }
}

.btn {
    font-weight: var(--custom-btn-text-weight, normal);
    &.btn-rounded {
        border-radius: 2rem;
    }
    &.btn-primary {
        color: $btn-text-color;
        background-color: $btn-background-color;
        border-color: $btn-background-color;
    }
    &.btn-ecom-primary {
        color: $btn-ecom-text-color;
        background-color: $btn-ecom-background-color;
        border-color: $btn-ecom-background-color;
        &:hover {
            color: $btn-ecom-hover-text-color;
            background-color: $btn-ecom-hover-background-color;
            border-color: $btn-ecom-hover-background-color;
        }
    }
    &.btn-featured {
        line-height: normal;
        color: $btn-featured-text-color;
        background-color: $btn-featured-background-color;
        border-color: $btn-featured-background-color;
        &:hover {
            color: $btn-featured-hover-text-color;
            background-color: $btn-featured-hover-background-color;
            border-color: $btn-featured-hover-background-color;
        }
        &.disabled,
        &[disabled] {
            color: $gray-600 !important;
            background-color: $gray-500 !important;
            border-color: $gray-500 !important;
            cursor: not-allowed;
        }
    }
    &.btn-featured-2 {
        line-height: normal;
        color: $btn-featured-2-text-color;
        background-color: $btn-featured-2-background-color;
        border-color: $btn-featured-2-background-color;
        &:hover {
            color: $btn-featured-2-hover-text-color;
            background-color: $btn-featured-2-hover-background-color;
            border-color: $btn-featured-2-hover-background-color;
        }
        &.disabled,
        &[disabled] {
            color: $gray-600 !important;
            background-color: $gray-500 !important;
            border-color: $gray-500 !important;
            cursor: not-allowed;
        }
    }
    &.btn-featured-reverse {
        color: $btn-featured-reverse-text-color;
        background-color: $btn-featured-reverse-background-color;
        border-color: $btn-featured-reverse-background-color;
        &:hover {
            color: $btn-featured-reverse-hover-text-color;
            background-color: $btn-featured-reverse-hover-background-color;
            border-color: $btn-featured-reverse-hover-background-color;
        }
    }
    &.btn-featured-reverse-2 {
        color: $btn-featured-2-reverse-text-color;
        background-color: $btn-featured-2-reverse-background-color;
        border-color: $btn-featured-2-reverse-background-color;
        &:hover {
            color: $btn-featured-2-reverse-hover-text-color;
            background-color: $btn-featured-2-reverse-hover-background-color;
            border-color: $btn-featured-2-reverse-hover-background-color;
        }
    }
    &.btn-featured-outline-2 {
        color: $btn-featured-2-background-color;
        background-color: $btn-featured-2-text-color;
        border-color: $btn-featured-2-background-color;
        &:hover,
        &.active {
            color: $btn-featured-2-hover-text-color;
            background-color: $btn-featured-2-hover-background-color;
            border-color: $btn-featured-2-hover-background-color;
        }
        &.disabled,
        &[disabled] {
            color: $gray-600 !important;
            background-color: $gray-500 !important;
            border-color: $gray-500 !important;
            cursor: not-allowed;
        }
    }
}

.input-group {
    > .input-group-prepend > .input-group-text {
        padding-right: 0.25rem;
    }

    > .input-group-append > .input-group-text {
        padding-left: 0.25rem;
    }

    .form-control {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
}

.input-group-sm {
    > .input-group-prepend > .btn,
    > .input-group-append > .btn {
        padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
    }

    > .input-group-prepend > .input-group-text {
        padding: $input-btn-padding-y-sm $input-btn-padding-x-sm $input-btn-padding-y-sm 0;
    }

    > .input-group-append > .input-group-text {
        padding: $input-btn-padding-y-sm 0 $input-btn-padding-y-sm $input-btn-padding-x-sm;
    }

    > .input-group-prepend > .btn {
        border-bottom-left-radius: $btn-border-radius-sm;
        border-top-left-radius: $btn-border-radius-sm;
    }

    > .input-group-append > .btn {
        border-bottom-right-radius: $btn-border-radius-sm;
        border-top-right-radius: $btn-border-radius-sm;
    }
}

.input-group-lg {
    > .input-group-prepend > .btn,
    > .input-group-append > .btn {
        padding: $input-btn-padding-y-lg $input-btn-padding-x-lg;
    }

    > .input-group-prepend > .input-group-text {
        padding: $input-btn-padding-y-lg $input-btn-padding-x-lg $input-btn-padding-y-lg 0;
    }

    > .input-group-append > .input-group-text {
        padding: $input-btn-padding-y-lg 0 $input-btn-padding-y-lg $input-btn-padding-x-lg;
    }

    > .input-group-prepend > .btn {
        border-bottom-left-radius: $btn-border-radius-lg;
        border-top-left-radius: $btn-border-radius-lg;
    }

    > .input-group-append > .btn {
        border-bottom-right-radius: $btn-border-radius-lg;
        border-top-right-radius: $btn-border-radius-lg;
    }
}

.form-group-lg {
    select,
    select.form-control {
        line-height: 1.5;
    }
}

// Buttons

.btn {
    font-size: 0.75rem;
    text-transform: uppercase;
}

.btn-lg {
    @include button-size(
        $btn-padding-y-lg,
        $btn-padding-x-lg,
        $btn-font-size-lg,
        $btn-line-height-lg,
        $btn-border-radius-lg
    );
}

.btn-sm {
    @include button-size(
        $btn-padding-y-sm,
        $btn-padding-x-sm,
        $btn-font-size-sm,
        $btn-line-height-sm,
        $btn-border-radius-sm
    );
}

.btn-outline-primary,
.btn-outline-secondary,
.btn-outline-success,
.btn-outline-danger,
.btn-outline-warning,
.btn-outline-info,
.btn-outline-light,
.btn-outline-dark {
    background-color: $white;
}

.btn-outline-primary,
.btn-outline-secondary {
    &:hover:not(:disabled),
    &:active:not(:disabled) {
        color: $white !important;
    }
}

// Badges

.badge {
    &-secondary {
        background-color: $gray-500;
        color: $white;
    }

    &-light {
        background-color: $gray-100;
    }

    &-warning {
        color: $white;
    }
}

.tag {
    padding: 4px 6px 4px;
}

// Navs

.nav-tabs {
    .nav-item + .nav-item {
        margin-left: 0;
    }

    .nav-link {
        height: 100%;
    }

    .nav-link,
    .nav-link:focus {
        background-color: transparent;
        border: none;
        box-shadow: inset 0 -1px 0 #ddd;
        color: $body-color;
        margin-right: 0;
        transition: all 0.2s;

        &:hover {
            background-color: transparent;
            box-shadow: inset 0 -2px 0 $primary;
            color: $primary;
        }
    }

    .nav-link.active,
    .nav-link.active:focus {
        border: none;
        box-shadow: inset 0 -2px 0 $primary;
        color: $primary;

        &:hover {
            border: none;
            color: $primary;
        }
    }

    .nav-link.disabled {
        box-shadow: inset 0 -1px 0 #ddd;
    }

    &.nav-justified {
        .nav-link,
        .nav-link:hover,
        .nav-link:focus,
        .nav-link.active,
        .nav-link.active:hover,
        .nav-link.active:focus {
            border: none;
        }
    }

    .dropdown-menu {
        margin-top: 0;
    }
}

.dropdown-menu {
    border: none;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    margin-top: 0;
}

// Navbar

.navbar {
    padding: 0;

    .navbar-toggler {
        border: 0;
    }

    .navbar-brand {
        margin-right: 0;
    }
}

.navbar-collapse {
    flex-grow: inherit;
}

@include media-breakpoint-down(md) {
    .navbar {
        .container {
            margin-left: 0;
            margin-right: 0;
            max-width: 100%;
        }
    }

    .navbar-collapse {
        flex-grow: 1;
    }
}

@include media-breakpoint-up(md) {
    .navbar {
        position: inherit;
    }
}

// TODO: Find new place for this.

.btn-oauth {
    text-transform: inherit;
}

.oauth-icon {
    height: 1rem;

    @include pr(1);
}

.oauth-salesforce {
    background-color: #00a1e0;
    border: none;
    color: $white;

    &:active,
    &:focus,
    &:hover {
        background-color: #00a1e0 !important;
        border: none;
        color: $white !important;
    }
}

.oauth-google {
    background-color: $white;
    border: thin solid #888;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.54);

    &:active,
    &:focus,
    &:hover {
        background-color: $white !important;
        border: thin solid #888;
        box-shadow: none;
        color: rgba(0, 0, 0, 0.54) !important;
    }
}

.oauth-facebook {
    background-color: #3b5998;
    border: none;
    color: $white;

    &:active,
    &:focus,
    &:hover {
        background-color: #3b5998 !important;
        border: none;
        color: $white !important;
    }
}

.list-group {
    &-item-action.active {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $white;
        }
    }
}

.popover {
    border: none;
}

// Modal

.modal-header {
    font-weight: $font-weight-bold;
}

.modal-body {
    min-height: 200px;
}

.fas,
.fal,
.far,
.fab {
    &:before {
        font-family: "FontAwesome";
        font-style: normal;
    }
    &.fa-star-half {
        text-align: left;
    }
}

.fa-lg {
    font-size: 1.25em;
}

.fa-xl {
    font-size: 1.5em;
}

.fa-2xl {
    font-size: 2em;
}

.bolle-icon {
    font-family: 'bolle'!important;
    font-size: 16px;
    font-weight: 400!important;
    font-style: normal!important;
    font-variant: normal!important;
    line-height: 1;
    width: 16px;
    height: 16px;
    text-transform: none!important;
    vertical-align: middle;
}

.border-bottom-dotted {
    border-bottom: dotted;
}

.border-right-dotted {
    border-right: dotted;
}

.border-left-dotted {
    border-left: dotted;
}

.border-top-dotted {
    border-top: dotted;
}

.border-dotted {
    border: dotted;
}
