@import "~slick/slick";

.product-carousel,
.recommendations-carousel,
.einstein-carousel,
.recommendations-upsell-carousel,
.carousel {
    height: 1px;
    visibility: hidden;

    &.slick-initialized {
        height: auto;
        visibility: visible;
    }
}

.recommendations-carousel,
.einstein-carousel,
.carousel {
    width: 100%;
}

.experience-carousel-productCarousel {
    > div {
        @include media-breakpoint-down(xs) {
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;
        }
        > .row {
            @include media-breakpoint-up(md) {
                @include mx(3);
            }
        }
    }
}

.product-carousel,
.lens-technology-carousel,
.recommendations-carousel,
.recommendations-upsell-carousel,
.banner-carousel,
.einstein-carousel,
.carousel {
    &.slick-slider {
        width: 100%;
    }

    .carousel-item {
        padding: 1px;
    }

    .carousel-item{
        &.video-container {
            min-height: 370px;
            position: relative;

            .video {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                border-width: 0;
            }
        }
    }

    .slick-prev,
    .slick-next {
        background-color: $white;
        border: 0;
        border-radius: 18px;
        box-shadow: 0 0 9px 0 rgba(119, 119, 119, 0.1);
        color: $primary;
        font-size: 0;
        height: 35px;
        position: absolute;
        top: 50%;
        transition: opacity 0.3s $default-cubic-bezier, box-shadow 0.3s $default-cubic-bezier;
        width: 35px;
        z-index: 2;

        &::before {
            color: $primary;
            opacity: 0.75;
            @include font-size(20px);
        }

        &.slick-disabled {
            opacity: 0.4;
        }

        &:hover,
        &:focus {
            box-shadow: 0 0 9px 0 rgba(119, 119, 119, 0.4);

            &::before {
                opacity: 1;
            }
        }
    }

    .slick-prev {
        left: -45px;

        &::before {
            @include fa-icon("\f104", "regular");
        }
    }

    .slick-next {
        right: -45px;

        &::before {
            padding-left: 0.2rem;
            @include fa-icon("\f105", "regular");
        }
    }

    .slick-dots {
        bottom: 0px;
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        position: absolute;
        text-align: center;
        width: 100%;

        li {
            cursor: pointer;
            display: inline-block;
            margin: 0 5px;
            padding: 0;
            position: relative;
        }

        button {
            background-color: $white;
            border: 1px solid $secondary;
            border-radius: calculate-rem(7px);
            cursor: pointer;
            display: block;
            font-size: 0;
            height: calculate-rem(14px);
            outline: none;
            padding: calculate-rem(5px);
            width: calculate-rem(14px);

            &::before {
                display: none;
            }

            &:hover,
            &:focus {
                outline: none;
            }
        }

        .slick-active {
            button {
                background-color: $secondary;
            }
        }
    }

    .slick-list {
        .slick-track {
            display: flex;
            .slick-slide > div {
                padding-right: 1rem;
            }
        }
        .slick-loading & {
            background: $white slick-image-url("ajax-loader.gif") center center no-repeat;
        }
    }
}

.experience-component.experience-carousel-bannerCarousel .slick-track {
    gap: 0 !important;
}

.pdp-slot-recommendation, 
.recommendations,
.experience-carousel-productCarousel {
    @include media-breakpoint-down(xs) {
        .slick-prev {
            left: 5px;
            top: 48%;
        }
        .slick-next {
            right: 5px;
            top: 48%;
        }
    }
    .product-recommendation {
        .product {
            width: 100%;
        }
        
    }
}