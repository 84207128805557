@import "~base/components/toastMessage";

.product-detail {
    .carousel-container {
        position: relative;

        .slick-paging {
            .slick-paging-image-container {
                height: 100px;
                width: 100px;
            }

            .slick-paging-btn {
                background-color: transparent;
                border: 0;
                cursor: pointer;
                margin-bottom: 0.5rem;
                opacity: 0.5;

                &.active {
                    opacity: 1;
                }
            }

            .slick-current .slick-paging-btn {
                opacity: 1;
            }

            .slick-prev,
            .slick-next {
                display: block;
                background: none;
                border: 0;
            }

            .slick-prev::before,
            .slick-next::after {
                display: block;
                font-family: "FontAwesome";
                font-style: normal;
            }

            .slick-prev::before {
                content: "\f106";
            }
            .slick-next::after {
                content: "\f107";
            }
        }

       @include stickers(50px);
    }

    .prices {
        @include font-size(16px);
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        padding-bottom: 0;
    }

    .hide-ecom, .hide-price {
        display: none !important;
    }

    .promotions {
        color: $promo-text;
    }

    .color-attribute {
        background-color: transparent;
        border: 0;
        height: calculate-rem(40px);
        margin-bottom: 0.5rem;
        margin-right: 0.5rem;
        padding: 2px;
        width: calculate-rem(40px);
        
        .swatch {
            background-repeat: no-repeat;
            background-clip: content-box;
            background-position: center;
            border: 1px solid transparent;
            padding: 2px 2px 2px 2px;
            background-size: 90% 90%;

            @include swatch(calculate-rem(45px), $white);

            &.selected {
                border-color: $swatch-border-active;
            }

            &.selectable {
                background-color: $gray-100; // in case the image is not loaded, have something to show
            }

            &.unselectable {
                opacity: 0.5;
                cursor: auto;
            }

            &.swatch-not-orderable {
                opacity: 0.50;

                &::after {
                    content: "";
                    width: 50px;
                    height: 2px;
                    transform: rotate(45deg);
                    display: block;
                    position: absolute;
                    background-color: $light-grey;
                    top: 20px;
                    left: -5px;
                }
            }
        }
    }

    .attribute, .lens-attributes {
        @include mt(0);

        label {
            display: block;
            font-weight: $font-weight-bold;
            text-transform: uppercase;

            @include font-size(14px);
            @include mb(3);
        }
    }

    .lens-attributes {
        background-color: $light-grey;
        padding: 0;
        color: $secondary;
    }

    .size-attribute {
        background-color: $white;
        border: 1px solid $search-refinement-size-border;
        font-weight: $font-weight-bold;
        min-height: 40px;
        position: relative;
        min-width: 40px;

        @include mr(2);
        @include mb(2);

        &.selected {
            background-color: $gray-900;
            border: 1px solid $gray-900;
            color: $white;

            &.swatch-not-orderable {
                background-color: $gray-650;
            }
        }

        &.swatch-not-orderable {
            opacity: 0.50;

            &::after {
                content: "";
                width: 67px;
                height: 2px;
                transform: rotate(33deg);
                display: block;
                position: absolute;
                background-color: $light-grey;
                top: 18px;
                left: -5px;
            }
        }

        &[disabled="disabled"] {
            border: 1px solid rgba($search-refinement-size-border, 0.2);
            color: rgba($search-refinement-size-border, 0.2);
            overflow: hidden;

            &::before {
                border-bottom: 1px solid rgba($search-refinement-size-border, 0.2);
                content: "";
                left: calc(50% - 16px);
                position: absolute;
                top: 0;
                transform: rotate(45deg);
                transform-origin: 0 center;
                width: 100%;
            }
        }
    }

    .delivery-icon{
        content: url('../../images/icons/product/delivery.svg');
        height: 40px;
        width: 40px;
    }

    .stock-icon{
        content: url('../../images/icons/product/stock.svg');
        height: 30px;
        width: 40px;
    }
}

.swatch a {
    text-decoration: none;
}

.primary-images {
    @include media-breakpoint-down(xs) {
        margin: 0;
        padding: 0;
    }
}

.availability-msg {
    @include mt(2);
    @include mb(0);
    font-weight: $font-weight-normal;
}

.shipping-dates{
    .green-color{
        color: $green;
    }
}

.prices-add-to-cart-actions {
    bottom: 0;
    left: 0;
    padding-left: 0;
    padding-right: 0;
    position: fixed;
    width: 100%;
    z-index: 9;

    @include media-breakpoint-down(xs) {
        background-color: $white;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

        @include p(2);
    }

    @include media-breakpoint-up(sm) {
        position: static;
    }

    .price {
        text-align: center;
    }
}

.cart-and-ipay {
    text-align: center;
}

.pdp-checkout-button {
    .add-to-cart {
        font-weight: var(--custom-add-to-cart-font-weight);
        @include p(3);
        @include font-size(14px);
    }
}

.add-to-cart-messages {
    @include toast-message();
}

.add-to-basket-alert {
    @include toast-alert();
}

.simple-quantity {
    margin-top: 1em;
}

.main-attributes {
    margin-top: 1em;
}

.size-chart {
    margin-top: 1.071em;
}

.bundle-item {
    border-bottom: 1px solid $hr-border-color;
    padding-bottom: 1em;

    &:last-child {
        border-bottom: none;
    }
}

.container.product-detail {
    margin-bottom: 2em;
    margin-top: 2em;

    @include media-breakpoint-only(xs) {
        margin-bottom: 0;
        margin-top: 0;
    }
}

.product-option:not(:first-child) {
    margin-top: 1.071em;
}

.size-attribute .size-box,
.size-attribute .size-value {
    display: block;
    font-size: 0.75rem;
    line-height: 0.7rem;
}

.size-attribute .size-9-in-x-4-in,
.size-attribute .size-7-in-x-325-in,
.size-attribute .size-180-cm-x-10-mm,
.size-attribute [data-attr-value="AEC"],
.size-attribute [data-attr-value="AD1"],
.size-attribute [data-attr-value="AA7"],
.size-attribute [data-attr-value="ADT"],
.size-attribute [data-attr-value="ADI"],
.size-attribute [data-attr-value="AD6"] {
    display: block;
    font-size: calculate-rem(10px);
    line-height: 0.7rem;
}

.choose-bonus-product-dialog {
    .bonus-product-item {
        .carousel-container {
            .slick-prev,
            .slick-next {
                background-color: transparent;
                box-shadow: none;
            }

            .slick-paging {
                display: none !important;
            }
        }
    }
}

.product-info {
    border-bottom: 1px solid $gray-300;
    border-left: 1px solid $gray-300;
    border-right: 1px solid $gray-300;

    &:first-of-type {
        border-top: 1px solid $gray-300;
    }

    .product-edit {
        color: $product-edit;

        a {
            color: $product-edit;
            text-transform: uppercase;
            position: relative;
            
            @include mr(2);
            @include font-size(12px);

            &:last-of-type {
                @include mr(0);
            }
        }
    }

    .line-item-availability-status {
        @include my(1);

        &::before {
            @include mr(1);
        }

        &.in-stock {
            color: $product-in-stock-color;
        }

        &.in-stock::before {
            @include fa-icon("\f058", "light");
        }
    }

    .bonus-line-item-msg {
        font-weight: $font-weight-bold;

        @include my(3);
    }
}

.promotion-information {
    display: flex;
    justify-content: space-between;
}


// PDP Content Sections

/* glass-traitement-section */
.pdp-section-list-item-badge span {
    display: inline-block;
    padding: 3px 6px;
    max-width: 48px;
    border-radius: 17px;
}

.bg-black {
    background: $black;
}

@include media-breakpoint-up(lg) {
    .drag-top {
        margin-top: -130px;
    }
}

/* polarization-benefits-section */
.polarization-benefits-section {
    background: linear-gradient($gray-100, $white);
}

.pdp-relative-text,
.pdp-relative-image {
    position: relative;
}

.pdp-absolute-text,
.pdp-absolute-image {
    position: absolute;
    top: 0;
    left: 0;
}

.pdp-relative-text,
.pdp-absolute-image {
    z-index: 1;
}

.pdp-absolute-text,
.pdp-relative-image {
    z-index: 2;
}

.pdp-absolute-top {
    top: 0;
}

.pdp-absolute-bottom {
    bottom: 0;
}

.pdp-absolute-bottom.pdp-relative-text {
    margin-top: 40%!important;

    @include media-breakpoint-up(lg) {
        margin-top: 30%!important;
    }
}

.before-after-polarization {
    position: relative;
    z-index: 1;
    margin-bottom: 5px;
}

.before-after-polarization-name {
    position: absolute;
    top: 50%;
    left: -10px;
    transform: rotate(-90deg);
    transform-origin: 0 50%;

    &.long-name {
        top: 60%;
    }
}

.filter-white {
    filter: brightness(1.5) opacity(0.6);
    -webkit-filter: brightness(1.5) opacity(0.6);
    -moz-filter: brightness(1.5) opacity(0.6);
    -o-filter: brightness(1.5) opacity(0.6);
    -ms-filter: brightness(1.5) opacity(0.6);
}

.product-sunglasses-context {
    .lens-attributes {
        background-color: transparent;
    }
}

.cart {
    .quantity-label {
        color: $product-edit;
        text-transform: uppercase;
        position: relative;
        
        @include mr(2);
        @include font-size(12px);

        &:last-of-type {
            @include mr(0);
        }
    }
}
