@charset "UTF-8";

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800;900&family=Roboto+Condensed:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@import "font-awesome/scss/font-awesome";

@font-face {
    font-family:'bolle';
    font-weight:400;
    font-style:normal;
    src:url(../fonts/bolle.eot);
    src:url(../fonts/bolle.eot?#iefix) format('embedded-opentype'),
        url(../fonts/bolle.woff) format('woff'),
        url(../fonts/bolle.woff2) format('woff2'),
        url(../fonts/bolle.ttf) format('truetype'),
        url(../fonts/bolle.svg#bolle) format('svg')
}

@font-face {
    font-family:'mustangregular';
    src: url('../fonts/mustang-webfont.eot');
    src: url('../fonts/mustang-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/mustang-webfont.woff2') format('woff2'),
    url('../fonts/mustang-webfont.woff') format('woff'),
    url('../fonts/mustang-webfont.ttf') format('truetype'),
    url('../fonts/mustang-webfont.svg#mustangregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@import "variables";
@import "bootstrap";
@import "core-theme";
@import "flag-icon";
@import "components/swatch";
@import "components/common";
@import "components/grid";
@import "components/carousel";
@import "components/header";
@import "components/minicart";
@import "components/footer";
@import "components/dropdown";
@import "components/discounts";
@import "components/promoContentTile";
@import "components/productTiles";
@import "components/headerSearch";
@import "components/applePay";
@import "components/social";
@import "components/pricing";
@import "components/productCommon";
@import "components/wishlistGlobal";
@import "components/ratings";
@import "components/spinner";
@import "components/tooltip";
@import "components/content";
@import "components/formFields";

// Page Designer
@import "experience/components/common";
@import "experience/components/assets/banner";
@import "experience/components/assets/categoryBanner";
@import "experience/components/assets/categoryTile";
@import "experience/components/assets/contentTile";
@import "experience/components/assets/image";
@import "experience/components/assets/sectionHeader";
@import "experience/components/layouts/layouts";
@import "experience/components/layouts/bannerCarousel";

// Base Components
@import "~base/components/notification";
@import "~base/components/tabs";

// Third Party
@import "contrib/flatpickr";

//Flag for input field phone
@import "~intl-tel-input/build/css/intlTelInput";
@import "utilities/phoneFlag";
