$sm-container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px
);

$sm-grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);

.container_sm {
    @include make-container();
    @include make-container-max-widths($sm-container-max-widths, $sm-grid-breakpoints);
}
