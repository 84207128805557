@import "../variables";

.site-footer {
    .site-footer_reinsurance {
        &,
        &__item {
            background-color: $site-footer-reinsurance-bg;
        }

        border-bottom: 1px solid $site-footer-separator;

        &__item {
            border: 0;

            a, p {
                color: $site-footer-reinsurance-text;
            }
        }
    }

    .site-footer_content {
        @include py(3);
        border-bottom: 1px solid $site-footer-separator;
    }

    .email-signup, .email-signup-redirect{
        @include m(2);

        .input-group-text {
            border-right: none;
            color: $secondary;
        }

        .input-group {
            background-color: $white;

            @include pl(2);
        }

        .form-control {
            border-left: none;
            box-shadow: none;
            padding-left: 0;
            border: none;
            &:focus {
                border: none;
            }
        }
        .form-group {
            margin-bottom: 0rem;
        }

        .warn.warn-success {
            color: $warn-success-text;
        }

        .warn.warn-danger {
            color: $warn-danger-text
        }

        .email-signup-form {
            max-width: 600px;
        }

        .btn.subscribe-email-footer, .btn.redirect-subscribe-email-footer {
            color: $btn-email-footer-color;
            background-color: $btn-email-footer-bgcolor;
            border-color: $btn-email-footer-border-color;
        }
    }

    .email-signup-redirect{
        .btn.redirect-subscribe-email-footer{
            margin-top: 12px;
            padding: 10px 20px 10px 20px;
            font-weight: bold;
        }
    }


    .site-footer_reinsurance-payment {
        .list-inline-item {
            width: 45px;
        }
    }

    .footer-section-border-bottom {
        border-bottom: 1px solid $site-footer-separator;
    }

    .footer-section-border-top {
        border-top: 1px solid $site-footer-separator;
    }

    .notice, .site-footer_reinsurance-payment {
        background-color: $site-footer-reinsurance-bg;
        color: $site-footer-reinsurance-text;
        font-size: 0.75rem;

        a {
            color: inherit;
            &:hover {
                text-decoration: underline;
            }
        }

        .list-inline {
            margin-bottom: 0;
        }

        @include media-breakpoint-down(sm) {
            & {
                @include px(3);
            }
        }
    }

    .back-to-top {
        -webkit-appearance: none;
        background-color: transparent;
        border: 0;
        bottom: 30px;
        color: $site-footer-back-to-top-color;
        cursor: pointer;
        opacity: 0;
        position: fixed;
        right: 20px;
        transition: opacity 0.2s ease-out;
        z-index: 99;

        .fa-stack {
            border-radius: 50%;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
            width: 2em;
        }

        &.show {
            opacity: 1;
        }

        @include media-breakpoint-down(md) {
            bottom: calculate-rem(20px);
            right: 0;
        }
    }

    .copyright {
        text-align: center;
    }

    @include toggle($site-footer-link);
}

.site-footer__inner-container {
    background-color: $site-footer-bg;
}

.site-footer-social {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;

    .site-footer-social__item {
        @include mx(2);

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }

        a {
            color: $site-footer-social;
            display: block;
            height: 100%;
            opacity: 0.9;
            text-align: center;
            transition: opacity 0.2s ease-out;
            width: 100%;

            @include font-size(12px);

            &:hover {
                opacity: 1;
            }
        }
        span {
            display: block;
            text-indent: -9999px;
        }
    }
}

.site-footer__brand {
    img {
        height: 70px;
    }
}

.site-footer__localization {
    color: $site-footer-link;

    .country-selector {
        cursor: pointer;

        .dropdown-menu {
            border: 0;
            z-index: 2500;
            border-radius: 0;
            box-shadow: $default-box-shadow;
            background-color: $site-footer-country-selector-background-color;

            li,
            a {
                background-color: $site-footer-country-selector-background-color;
            }
        }

        .dropdown-item {
            color: $tertiary;
            font-size: 0.75rem;
            padding: 0.25rem 1rem;
            &:hover,
            &:active {
                background-color: $site-footer-country-selector-link-hover-color;
            }
        }

        &:hover {
            .dropdown-menu {
                display: block;
                max-height: 208px;
                overflow-x: hidden;
                overflow-y: auto;
                scrollbar-color: #888 #d3d3d3; /* thumb and track color */
                scrollbar-width: thin;
            }

            /* width */
            .dropdown-menu::-webkit-scrollbar {
                width: 10px;
            }
            /* Track */
            .dropdown-menu::-webkit-scrollbar-track {
                background: #d3d3d3;
            }
            /* Handle */
            .dropdown-menu::-webkit-scrollbar-thumb {
                background: #888;
            }
            /* Handle on hover */
            .dropdown-menu::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
        }
    }

    .country-selector_button {
        color: $site-footer-countryselector-text;
    }

    .country-selector {
        color: $site-footer-countryselector-text;
        font-size: 0.75rem;
        text-transform: uppercase;
    }
}

.site-footer__menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

.site-footer__header,
.site-footer__menu-header {
    @include mr(3);

    color: $site-footer-header;
    @include font-size(16px);
}

.email-signup-label{
    p {
        margin-bottom: 0 !important;
    }
}

.site-footer__menu-header {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    font-family: var(--custom-footer-header-font, var(--font-family-sans-serif));
}

a.site-footer__menu-link {
    color: $site-footer-link;

    @include font-size(12px);
}

.validate-privacypolicy label, p.legal-privacypolicy {

    font-size: 10px;
    line-height: 13px;
    color: $description-input-color;
    text-align: left;
    margin: 5px 0 0;
    width: 100%;

    a {
        color: $description-input-color;
        text-decoration: underline;
    }

}

.consent-tracking {
    background-color: $consent-tracking-bg;
    bottom: 0px;
    color: $consent-tracking-text;
    font-size: 0.75rem;
    position: fixed;
    right: auto;
    z-index: 2000;

    a {
        color: $secondary;
    }

    .consent-tracking_close {
        color: $consent-tracking-text;
        margin-left: 1rem;
    }
}

@include media-breakpoint-up(md) {
    .site-footer__menu {
        .collapse {
            display: block;
            height: auto !important;
            visibility: visible;
        }
    }

    .site-footer__menu-item {
        line-height: 1.25rem;
    }
}

@include media-breakpoint-down(md) {
    .site-footer__menu-header {
        cursor: pointer;
    }

    .site-footer__menu-header,
    .site-footer__menu-item {
        color: $site-footer-link;
        padding: 0.25rem 0;
    }

    .consent-tracking {
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
    }
}
