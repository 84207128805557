.veil {
    height: 100%;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 100;

    .underlay {
        background-color: $spinner-backdrop-bg;
        height: 100%;
        left: 0;
        opacity: $spinner-backdrop-opacity;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.spinner {
    display: inline-block;
    height: 64px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
}

.spinner div {
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    background: $secondary;
    border-radius: 50%;
    height: 11px;
    position: absolute;
    top: 27px;
    width: 11px;
}

.spinner div:nth-child(1) {
    animation: spinner1 0.6s infinite;
    left: 6px;
}

.spinner div:nth-child(2) {
    animation: spinner2 0.6s infinite;
    left: 6px;
}

.spinner div:nth-child(3) {
    animation: spinner2 0.6s infinite;
    left: 26px;
}

.spinner div:nth-child(4) {
    animation: spinner3 0.6s infinite;
    left: 45px;
}

@keyframes spinner1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes spinner3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes spinner2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(19px, 0);
    }
}
