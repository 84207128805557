.category-banner {
    overflow: hidden;
    position: relative;

    .category-banner-image-wrapper {
        height: 100%;
        width: 100%;

        .banner-image-filter {
            filter: brightness(70%);
        }
    }

    .category-banner-image {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    .category-banner-overlay-wrapper {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .category-banner-overlay {
        height: 100%;
    }

    .category-banner-overlay-inner {
        color: $default-hero-color;
        padding: 0rem;

        .header {
            font-size : 3rem;
            font-weight : bold;
            @include media-breakpoint-down(xs){
                font-size : 2.375rem;
            }
        }
    }

    .banner-subheading {
        @include mb(3);
    }

    .btn {
        border-radius: 3rem;
        font-size: 0.875rem;
        padding: 0.5rem 2rem;
    }
}
