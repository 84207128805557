@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import 'variables';

@import 'experience/fonts';

@import '~core/global';

:root {
    --custom-menu-category-name: var(--font-family-accent);
    --custom-menu-category-name-weight: bold;
    --custom-btn-text-weight: 500;
    --custom-footer-header-font: var(--font-family-accent);
    --custom-mobile-level-1-2-font-family: var(--font-family-accent);
    --custom-breadcrumb-font-family: var(--font-family-accent);
    --custom-product-name-font-family: var(--font-family-accent);
    --custom-add-to-cart-font-weight: bold;
}